.riderReport h1,
.riderReport h2,
.riderReport h3,
.riderReport h4,
.riderReport h5,
.riderReport h6,
.riderReport p {
  margin: 0px;
}

.riderReport {
  float: none;
}

.riderReport .buttonPdf {
  display: flex;
  align-items: center;
  justify-content: center;
}

.riderReport .buttonPdf .pdfBtn {
  margin: 0px 10px;
  height: 48px;
  width: 140px;
  background: transparent;
  border: none;
  box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
  border-radius: 90px;
  font-weight: bold;
}

.riderReport .toggleBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.riderReport .toggleBtn .tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
  padding: 0.75rem;
  border-radius: 99px;
}

.riderReport .toggleBtn .tabs * {
  z-index: 2;
}

.riderReport .toggleBtn input[type="radio"] {
  display: none;
}

.riderReport .toggleBtn .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 110px;
  font-size: 0.85rem;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
}

.riderReport .toggleBtn .notification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-left: 0.75rem;
  border-radius: 50%;
  background-color: brown;
  transition: 0.15s ease-in;
}

.riderReport .toggleBtn input[type="radio"]:checked + label {
  color: white;
}

.riderReport .toggleBtn input[type="radio"]:checked + label > .notification {
  background-color: white;
  color: #fff;
}

.riderReport .toggleBtn input[id="radio-1"]:checked ~ .glider {
  transform: translateX(0);
}

.riderReport .toggleBtn input[id="radio-2"]:checked ~ .glider {
  transform: translateX(100%);
}

.riderReport .toggleBtn input[id="radio-3"]:checked ~ .glider {
  transform: translateX(200%);
}

.riderReport .toggleBtn .glider {
  position: absolute;
  display: flex;
  height: 29px;
  width: 110px;
  background-color: brown;
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}

@media (max-width: 700px) {
  .riderReport .toggleBtn .tabs {
    transform: scale(0.6);
  }
}
.inneRiderReport {
    float: none;
  }
  .inneRiderReport .reportDiv {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px calc(100% - 900px);
    float: none;
  }
  .inneRiderReport .reportDiv .report {
    border: 1px solid black;
    margin: 3px;
    width: 340px;
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: none !important;
  }
  .inneRiderReport .reportDiv .report .innerReport {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .inneRiderReport .reportDiv .report .innerReport h2 {
    font-size: 40px;
  }
  .inneRiderReport .reportDiv .report .innerReport h5 {
    font-size: 20px;
  }
  .inneRiderReport .reportDiv .report .innerReport h6 {
    font-size: 15px;
  }
  .inneRiderReport .reportDiv .report .innerReport p {
    font-size: 16px;
  }
  .inneRiderReport .reportDiv .pageBreak {
    page-break-after: always;
  }
  
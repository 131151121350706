.reportTable table {
  width: 100% !important;
  border-collapse: collapse;
}

.reportTable .mainHeading {
  height: 60px;
}

.reportTable .mainHeading th {
  font-size: 24px;
}

.reportTable th {
  background-color: #be7976;
}

.reportTable th,
.reportTable td {
  height: 50px;
  text-align: center;
  border: 1px solid black;
}

.reportTable .pagePrint {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}

.reportTable .pagePrint .download-table-xls-button {
  background: #001529;
  border: none;
  color: white;
  padding: 4px 13px;
  border-radius: 8px;
}

.reportTable .noneRow {
  display: none;
}

.reportTable table {
    width: 100%;
  }
  
  .reportTable table .mainHeading {
    height: 60px;
  }
  
  .reportTable table th {
    font-size: 24px;
    background-color: #BE7976;
  }
  
  .reportTable table th,
  .reportTable table td {
    height: 50px;
    text-align: center;
    border: 1px solid black;
  }
  
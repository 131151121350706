.loginForm {
    height: 100vh;
    display: flex;
}

.loginForm .sideBanner {
    background-color: #001529;
    width: 300px;
}

@media screen and (max-width: 1000px) {
    .loginForm .sideBanner {
        display: none;
    }
}

.loginForm .sideBanner .text {
    padding: 70px 0px 0px;
}

.loginForm .sideBanner .text h1 {
    font-size: 30px;
    color: white;
    text-align: center;
}

.loginForm .formDiv {
    width: calc(100% - 300px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1000px) {
    .loginForm .formDiv {
        width: calc(100%);
    }
}

.loginForm .formDiv h2 {
    color: #001529;
    font-weight: 700;
    font-size: 40px;
}

.loginForm .login-form {
    width: 340px;
}

.loginForm .login-form-forgot {
    float: right;
}

.ant-col-rtl .loginForm .login-form-forgot {
    float: left;
}

.loginForm .login-form-button {
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
margin: 0px;
}

#components-layout-demo-custom-trigger .trigger {
padding: 0 24px;
font-size: 18px;
line-height: 64px;
cursor: pointer;
transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
height: 32px;
margin: 16px;
background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
background: #fff;
}

.layOutLogo {
display: flex;
text-align: center;
align-items: center;
justify-content: center;
padding: 20px;
}

.layOutLogo h2 {
color: white;
font-size: 26px;
font-weight: bold;
}

.ant-layout-sider.ant-layout-sider-dark {
height: 100vh;
position: sticky;
top: 0;
}

.ant-layout-header.site-layout-background {
position: sticky;
top: 0;
padding: 0px 16px !important;
border-bottom: 1px solid #00000014;
box-shadow: 0px 0px 5px -2px black;
z-index: 10;
display: flex;
align-items: center;
justify-content: space-between;
}

.ant-layout-header.site-layout-background h3 {
text-transform: capitalize;
}

.ant-layout-header.site-layout-background .user {
display: flex;
line-height: 1;
}

.ant-layout-header.site-layout-background .user a {
margin: 0;
line-height: 1;
display: flex;
align-items: center;
margin: 0px 10px;
color:black
}

.ant-layout-content.site-layout-background {}

.loader {
position: absolute;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background: white;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
z-index: 999999999999999;
}

.loader h5 {
font-size: 30px;
color: #1890ff;
font-weight: bold;
}

button {
  cursor: pointer;
}

.CowPage h1,
.CowPage h2,
.CowPage h3,
.CowPage h4,
.CowPage h5,
.CowPage h6,
.CowPage p {
  margin: 0px;
}

.CowPage .innerCow .toolBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #0000001f;
  padding-bottom: 20px;
}

.CowPage .innerCow .toolBar h3 {
  margin: 0px;
  font-size: 17px;
}

.CowPage .innerCow .toolBar .toolBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CowPage .innerCow .toolBar .toggleBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CowPage .innerCow .toolBar .toggleBtn .tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
  padding: 0.75rem;
  border-radius: 99px;
}

.CowPage .innerCow .toolBar .toggleBtn .tabs * {
  z-index: 2;
}

.CowPage .innerCow .toolBar .toggleBtn input[type="radio"] {
  display: none;
}

.CowPage .innerCow .toolBar .toggleBtn .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 110px;
  font-size: 0.85rem;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
}

.CowPage .innerCow .toolBar .toggleBtn .notification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-left: 0.75rem;
  border-radius: 50%;
  background-color: brown;
  transition: 0.15s ease-in;
}

.CowPage .innerCow .toolBar .toggleBtn input[type="radio"]:checked + label {
  color: white;
}

.CowPage
  .innerCow
  .toolBar
  .toggleBtn
  input[type="radio"]:checked
  + label
  > .notification {
  background-color: white;
  color: #fff;
}

.CowPage .innerCow .toolBar .toggleBtn input[id="radio-1"]:checked ~ .glider {
  transform: translateX(0);
}

.CowPage .innerCow .toolBar .toggleBtn input[id="radio-2"]:checked ~ .glider {
  transform: translateX(100%);
}

.CowPage .innerCow .toolBar .toggleBtn input[id="radio-3"]:checked ~ .glider {
  transform: translateX(200%);
}

.glider {
  position: absolute;
  display: flex;
  height: 29px;
  width: 110px;
  background-color: brown;
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}
@media (max-width: 700px) {
  .tabs {
    transform: scale(0.6);
  }
}

.cowBtn {
  background: transparent;
  color: gray;
  font-weight: bold;
  padding: 3px 20px;
  border: none;
  outline: none;
  &.active {
    border: 1px solid brown;
    color: brown;
  }
}
.hissaBtn {
  background: transparent;
  color: gray;
  font-weight: bold;
  padding: 3px 20px;
  border: none;
  outline: none;
  &.active {
    border: 1px solid brown;
    color: brown;
  }
}

.addCowBtn {
  font-weight: bold;
  padding: 7px 20px;
  border: navajowhite;
  margin-left: 10px;
  background: green;
  color: white;
  outline: aliceblue;
  border-radius: 90px;
}
.filterDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}

.filterDiv .search {
  border: 1px solid #00000030;
  padding: 2px 10px;
  margin-right: 110px;
}

.filterDiv .search span {
  margin-right: 5px;
}

.filterDiv .search input {
  border: none;
  outline: none;
}
.boxDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.boxDiv .innerboxDiv {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2px;
}

.boxDiv .hissaBox {
  width: 220px;
  height: 220px;
  box-shadow: 0px 0px 5px -2px black;
  position: relative;
  margin: 23px;
}

.boxDiv .hissaBox .hissaBtn {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -20px);
  display: flex;
}
.checkboxLabel {
  display: flex;
  align-items: center;
}
.noDataMessage {
  font-size: 16px;
  color: gray;
  margin-top: 10px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkboxisPaid {
  margin-right: 5px;
}
.boxDiv .hissaBox .hissaBtn .editBtn {
  color: white;
  background-color: #1890ff;
  margin-right: 5px;
  border: none;
  padding: 3px 16px;
  border-radius: 5px;
  font-size: 15px;
}
//for contribution

.boxDiv .hissaBox .hissaBtn .deleteBtn {
  color: white;
  background-color: red;
  border: none;
  padding: 3px 16px;
  border-radius: 5px;
  font-size: 15px;
  margin-right: 5px;
}

.boxDiv .hissaBox .hissaBtn .printBtn {
  background-color: green;
  border: none;
  padding: 3px 16px;
  border-radius: 5px;
  font-size: 15px;
  color: white;
}

.boxDiv .cow {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 20px 0px;
  text-align: center;
  height: 100%;
}

.boxDiv .cow .innerCow {
  margin-top: 20px;
}

.boxDiv .cow h3 {
  font-size: 25px;
}

.boxDiv .cow h5 {
  font-size: 20px;
}

.boxDiv .cow p {
  color: red;
}

.boxDiv .cow .closed {
  color: green;
}

.boxDiv .cow .open {
  color: red;
}

.boxDiv .cow h6 {
  font-size: 16px;
}
.boxDiv .cow h6 .hissaNumber {
  color: green;
  font-size: 20px;
  margin-bottom: -2px;
}

.boxDiv .cow .phoneNumber {
  font-size: 13px;
  margin-top: -2px;
  color: black;
}
.addCowForm {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cowNumber {
  padding: 0px 30px 0px 0px;
}
.addCowForm input {
  border: 1px solid black;
  outline: none;
}
.addCowForm .daySelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.daysDiv {
  border: 1px solid black;
  width: 45px;
  text-align: center;
  cursor: pointer;
}
.modernSelect {
  width: 200px;
  margin-top: 10px;
  border: 1px solid black;
}
.daysDiv.active {
  background: green;
  color: white;
  border: 1px solid green;
}
.HissaModalDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.HissaModalDiv button {
  background-color: green;
  color: white;
  border: green;
  font-weight: bold;
  padding: 3px 10px;
}
.AddHissaModal {
  max-width: 900px;
  width: 100% !important;
}
.AddHissaModal .cancelBtn {
  border: 1px solid #00000054;
  background-color: transparent;
  padding: 3px 10px;
  border-radius: 3px;
  width: 60px;
}
.AddHissaModal .saveBtn {
  background-color: #40a9ff;
  padding: 3px 10px;
  border-radius: 3px;
  border: 1px solid #40a9ff;
  width: 60px;
  margin: 0px 10px;
}
.AddHissaModal .printBtn {
  background-color: #52c41a;
  padding: 3px 10px;
  border-radius: 3px;
  border: 1px solid #52c41a;
  width: 60px;
}
.AddHissaModal .copyBtn {
  margin: 0px 5px;
  background-color: #c41a1a;
  padding: 3px 10px;
  border-radius: 3px;
  border: 1px solid #c41a1a;
  width: 60px;
  color: white;
  font-weight: bold;
}
.AddHissaModal .pasteBtn {
  margin: 0px 5px;
  background-color: #1ac47a;
  padding: 3px 10px;
  border-radius: 3px;
  border: 1px solid #1ac47a;
  width: 60px;
  color: white;
  font-weight: bold;
}
.AddHissaForm .daysHissa {
  display: flex;
  align-items: center;
  float: right;
}

.AddHissaForm .daysHissa .daySelect {
  font-size: 14px;
  border: 1px solid black;
  padding: 4px 10px;
  margin-right: 3px;
  margin-bottom: 10px;
  cursor: pointer;
}

.AddHissaForm .daysHissa .daySelect.active {
  background-color: #dda9aa;
  color: #423d8a;
  border-color: #dda9aa;
}

.AddHissaForm .slipheader {
  width: 100%;
}

.AddHissaForm .innerForm {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.AddHissaForm .innerForm .hissaInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.AddHissaForm .innerForm .hissaInfo h5 {
  margin: 0px;
  /* width: 50px; */
  display: flex;
  align-items: flex-end;
  margin-right: 8px;
  font-size: 14px;
}

.AddHissaForm .innerForm .hissaInfo .date {
  width: 300px;
  padding: 11px;
  display: flex;
  align-items: center;
}

.AddHissaForm .innerForm .hissaInfo .smallBoxInput {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.AddHissaForm .innerForm .hissaInfo .smallBoxInput input {
  width: 30px;
  height: 30px;
  text-align: center;
  outline: none;
}

.AddHissaForm .innerForm .hissaInfo .smallBoxInput.cowNoInput input {
  width: 60px;
}

.AddHissaForm .innerForm .hissaInfo .smallBoxInput p {
  padding: 0px 10px;
  margin: 0;
}

.AddHissaForm .innerForm .hissaInfo .cowNo,
.AddHissaForm .innerForm .hissaInfo .HissaNo {
  display: flex;
  align-items: center;
}
.label {
  display: flex;
  padding: 11px;
  .input {
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    font-size: 14px;
  }
  .h5 {
    margin: 0px;
    display: flex;
    align-items: flex-end;
    margin-right: 8px;
    font-size: 14px;
  }
}
.w75 {
  width: 75%;
}
.w25 {
  width: 25%;
}
.w100 {
  width: 100%;
  &.checkImage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img {
      width: 200px;
    }
  }
}
.w50 {
  width: 50%;
}
.w33 {
  width: 33%;
}
.mobNo {
  .input {
    width: calc(100% - 110px);
  }
  .h5 {
    width: 110px;
  }
}
.bookBy {
  .input {
    width: calc(100% - 70px);
  }
  .h5 {
    width: 70px;
  }
}
.expected {
  .input {
    width: calc(100% - 130px);
  }
  .h5 {
    width: 130px;
  }
}
.houseNo {
  .h5 {
    width: 100px;
  }
}
.checkboxs {
  display: flex;
  .h5 {
    display: block;
    width: 130px;
  }
  .input {
    width: calc(100% - 150px);
  }
  .checkBoxes {
    margin-left: 10px;
    padding: 0px !important;
  }
  .instructionBox {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    .div {
      .p {
        margin: 0px;
        margin: 0px 10px;
        font-size: 16px;
      }
    }
  }
}
.confirmation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px;
}
.confirmation p {
  margin: 0px;
}
.confirmation .Category {
  border-radius: 14px;
  border: 2px solid;
  width: 93px;
  height: 73px;
}
.confirmation .Category p {
  margin: 0;
  border-bottom: 2px solid black;
  text-align: center;
  padding: 6px;
  font-size: 12px;
}
.confirmation .Category h6 {
  font-size: 20px;
  margin: 0px;
  text-align: center;
}
.confirmation .signature {
  width: 300px;
  border: 2px solid black;
}
.confirmation .signature .signDiv {
  width: 100%;
  height: 60px;
  text-align: center;
}
.confirmation .signature p {
  text-align: center;
}
.confirmation .website {
  text-align: center;
  width: 300px;
  padding: 10px;
  display: flex;
  justify-content: center;
  font-size: 18px;
}
.additionInstruction .intructInput input {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  width: calc(100% - 120px);
}

.khalein {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.redWarn {
  margin: 0;
  font-size: 10px;
  color: red;
}
.AddHissaForm .innerForm .name .intructInput input {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  width: 520px;
  margin-top: 10px;
  margin-left: 7px;
}
.AddHissaForm .innerForm .sonof .intructInput input {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  width: 170px;
  margin-top: 12px;
  margin-left: 7px;
}
.AddHissaForm .innerForm .street .intructInput input {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  width: 800px;
  margin-top: 12px;
  margin-left: 12px;
}
.AddHissaForm .innerForm .ptcl .intructInput input {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  width: 240px;
  margin-top: 12px;
  margin-left: 5px;
}
.AddHissaForm .innerForm .booked .intructInput input {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  width: 190px;
  margin-top: 15px;
  margin-left: 12px;
}
.copyCowBtn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 2px;
  padding: 8px 16px;
  background-color: #1890ff; /* Blue background color */
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #fff; /* Text color */
  transition: background-color 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.copyCowText {
  margin-left: 4px;
  display: none;
}

.copyCowBtn:hover {
  background-color: #40a9ff; /* Hover background color */
}

.copyCowBtn:hover .copyCowText {
  display: inline;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 12px;
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.modalContainer {
  position: relative;
  width: 900px;
  height: 600px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.styledTable {
  width: 100%;
  border-collapse: collapse;
}

.styledTable th,
.styledTable td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.styledTable th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.styledTable tbody tr:hover {
  background-color: #f5f5f5;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* .dashboard {
    height: 100%;
    display: flex;
    align-items: center;
}

.dashboard .innerDashboard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.dashboard .innerDashboard .stats {
    padding: 10px;
    width: 250px;
    height: 250px;
    box-shadow: 0px 0px 5px -2px black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.dashboard .innerDashboard .stats h3 {
    font-size: 50px;
    margin: 0 !important;
}

.dashboard .innerDashboard .stats h5 {
    margin: 0 !important;
    font-size: 21px;
} */
.dashboard {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
  }
  
  .innerDashboard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 30px;
  }
  
  .stats {
    padding: 20px;
    width: 250px;
    height: 250px;
    box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: #fff;
  }
  
  .stats h3 {
    font-size: 50px;
    margin: 0;
    color: #333;
    font-weight: bold;
  }
  
  .stats h5 {
    margin: 0;
    font-size: 21px;
    color: #666;
    font-weight: bold;
  }
  
  @media only screen and (max-width: 768px) {
    .stats {
      width: 100%;
      margin-bottom: 30px;
    }
  }
  